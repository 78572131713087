<template>
  <div class="home " id="home">
		<!-- <video
			v-if="$store.state.isPC || tabletPC"
			class="video_el"
			:src="$store.state.isPC || tabletPC ? P_src : M_src"
			ref="videoPlayer"
			preload="auto"
			muted
			autoplay
			loop
			id="movieVideo"
			controlsList="nodownload noplaybackrate "
			webkit-playsinline="true"
			playsinline="true"
			x-webkit-airplay="true"
			x5-video-player-type="h5-page"
			x5-video-player-fullscreen="true"
			x5-video-orientation="portraint"
		></video> -->
		<video
			v-if="$store.state.isPC || tabletPC"
			class="video_el"
			:src="$store.state.isPC || tabletPC ? P_src : M_src"
			autoplay
			loop
			muted
			controlslist="nodownload"
			id="videoplayer"
			x5-playsinline="true"
			playsinline="true"
			webkit-playsinline="true"
			x-webkit-airplay="true"
			x5-video-player-type="h5-page"
			x5-video-player-fullscreen="true"
			x5-video-orientation="portraint"
		></video>
    
		<div v-else class="M_gif"></div>
    <img class="logo" alt="logo" :src="$store.state.isPC ? P_logo : M_logo">
		<img class="bottom_logo" :src="$store.state.isPC ? P_logo_b : M_logo_b" alt="">
  </div>
</template>

<script>
// @ is an alias to /src

export default {
	name: "HomeView",
	data() {
		return {
			P_src: "https://static.ice.design/vacuumerse/vacuumerse_pc.mp4",
			M_src: "https://static.ice.design/vacuumerse/vacuumerse_m.mp4",
			P_logo: require("../assets/logo_pc.png"),
			M_logo: require("../assets/logo_m.png"),
			P_logo_b: require("../assets/bottom_logo_pc.png"),
			M_logo_b: require("../assets/bottom_logo_m.png"),
			isPlay: true,
			tabletPC: false
		}
	},
	methods: {},
	created () {
		// 是否是平板电脑
		if (/mobile/i.test(navigator.userAgent) && !/ipad|tablet/i.test(navigator.userAgent)) {
			console.log("it's a phone");
		} else {
			this.tabletPC = true; // it's a tablet PC
		}
	},
	mounted () {
		window.οncοntextmenu = function() {
			return false;
		};
	},
};
</script>

<style lang="scss" scoped>
.home,
.video_el,
.video_box {
  width: 100%;
  height: 100%;
}
.home {
	position: relative;
	.video_el {
		object-fit: fill;
		object-position: center center;
		display: block;
		background: #000;
	}
	.M_gif {
		width: 100%;
		height: 100%;
		background: url("https://static.ice.design/vacuumerse/vacuumerse_m.gif");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.logo {
		width: 33.85%;
		max-width: 650px;
		height: auto;
		display: block;
		position: absolute;
		left: 50%;
		top: 23.5%;
		transform: translateX(-50%);
		z-index: 3;
	}
	.bottom_logo {
		position: absolute;
		top: 76.6%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 3;
		width: 45.31%;
		max-width: 870px;
	}
	@media only screen and (max-width: 767px) {
		.logo {
			width: 71.7%;
			height: auto;
			top: 28.17%
		}
		.bottom_logo {
			width: 85.1%;
			height: auto;
			top: 82.6%;
		}
	}
	video::-internal-media-controls-download-button {
    display:none;
	}
	video::-webkit-media-controls-enclosure {
			overflow:hidden;
	}
	video::-webkit-media-controls-panel {
			width: calc(100% + 30px); 
	}

}
</style>