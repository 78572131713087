<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
	methods: {
		...mapMutations(["setIsPC"]),
		isPc() {
			let userAgentInfo = navigator.userAgent;
			let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
			let getArr = Agents.filter(i => userAgentInfo.includes(i));
			return getArr.length ? false : true;
		}
	},
	mounted() {
		this.setIsPC(this.isPc());
	}
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
}
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
body,
html,
#app {
	width: 100%;
	height: 100%;
}
</style>
