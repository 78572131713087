import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		isPC: true
  },
  getters: {
  },
  mutations: {
		setIsPC(state, status) {
			state.isPC = status;
		}
  },
  actions: {
  },
  modules: {
  }
})
